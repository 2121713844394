import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ApiResp } from '@app/types/global.type';
import { Observable, map } from 'rxjs';

export interface SFTP {
  id: number;
  folderName: string;
  publicIps: string[];
  sftpOptin: boolean;
  bulkPdfOptin: boolean;

  step: string;
  ticketNumber: string;
  message: string;
}

export interface SFTPCreate {
  folderName: string;
  publicIps: string[];
  bulkPdfOptin: boolean;
}

export interface SFTPUpdate extends SFTPCreate {
  id: number;
  sftpOptin: boolean;

  ticketNumber?: string;
}

export interface MaskedKey {
  id: number;
  maskedKey: string;
  createDate: string;
  createdBy: string;
  updateDate: string;
  updatedBy: string;
}

@Injectable({
  providedIn: 'root',
})
export class SFTPService extends BaseService {
  getSFTPInfo(): Observable<SFTP> {
    return this.httpClient.get<ApiResp>(`/mygarnet/sftp/setting/details`).pipe(
      map((resp) => {
        if (resp.status) return resp.response;
        return null;
      }),
    );
  }

  createSFTP(payload: SFTPCreate): Observable<ApiResp> {
    return this.httpClient.post<ApiResp>(`/mygarnet/sftp/setting/create`, payload).pipe(
      map((resp) => {
        return this.handleFormResp(resp);
      }),
    );
  }

  updateSFTP(payload: SFTPUpdate): Observable<ApiResp> {
    return this.httpClient.post<ApiResp>(`/mygarnet/sftp/setting/edit`, payload).pipe(
      map((resp) => {
        return this.handleFormResp(resp);
      }),
    );
  }

  //sftp key generation
  generateSFTPKey(): Observable<Blob> {
    return this.httpClient.get('/mygarnet/sftp/encryption/generate/key', {
      responseType: 'blob',
    });
  }

  //sftp key
  getSFTPKey(): Observable<MaskedKey> {
    return this.httpClient.get<ApiResp>('/mygarnet/sftp/encryption/get/key').pipe(
      map((resp) => {
        if (resp.status) return resp.response;
        return null;
      }),
    );
  }
}
